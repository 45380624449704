<template>
    <v-dialog v-model="getDataDialog" max-width="50%">
        <template v-slot:activator="{ on }">
            <v-list-item v-on="on">
                <v-list-item-title>
                    <div class="d-flex align-center">
                        <img width="35" height="35" :src="customAppIcon(connectorData.appShortName)" />
                        <span class="ml-3 font-weight-bold subtitle-2 secondary--text">{{ connectorData.appName }}</span>
                    </div>
                </v-list-item-title>
            </v-list-item>
        </template>
        <v-card>
            <v-card-text v-if="connectorData">
                <div class="d-flex justify-space-between align-center pt-3 w-100">
                    <div class="d-flex align-center">
                        <img width="35" height="35" :src="customAppIcon(connectorData.appShortName)" />
                        <span class="ml-2 subtitle-2">{{ connectorData.appName }}</span>
                    </div>
                    <div class="d-flex flex-column">
                        <div v-for="(item, index) in databaseConnectionSecrets" :key="index" class="d-flex align-center">
                            <span class="font-weight-bold text-uppercase secondary--text" v-html="item.itemName"></span>
                            :
                            <span v-if="item.hideSecret">&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</span>
                            <span v-else>
                                <span>{{ item.data }}</span>
                            </span>
                            <copy-to-clipboard
                                :textToCopy="item.data"
                                buttonClass="caption font-weight-bold"
                                buttonColor="primary"
                                buttonName="copy to clipboard"
                                :iconButton="true"></copy-to-clipboard>
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>
                <v-radio-group v-model="selectedConnectorOption">
                    <v-radio label="Connect from Nuvolos" :value="connectorOptions.FROM_NUVOLOS"></v-radio>
                    <v-card
                        :elevation="selectedConnectorOption === connectorOptions.FROM_NUVOLOS ? 2 : 0"
                        :disabled="selectedConnectorOption === connectorOptions.FROM_LOCAL_COMPUTER"
                        outlined>
                        <v-card-text>
                            <div class="d-flex flex-column">
                                <v-banner two-line>
                                    <v-avatar slot="icon" color="white" size="60">
                                        <v-icon icon="info" color="info">info</v-icon>
                                    </v-avatar>
                                    <span class="info--text subtitle-2">
                                        Paste the code shown below into a running {{ connectorData.appName }} session in Nuvolos to execute your query.
                                    </span>
                                </v-banner>
                                <div class="d-flex align-center justify-space-between mt-5" style="background-color: rgba(23, 168, 193, 0.1); width: 100%">
                                    <span class="mb-0 mt-0 ml-2 font-weight-bold caption" style="color: rgba(0, 0, 0, 0.54)">Nuvolos Connector</span>
                                    <copy-to-clipboard
                                        :textToCopy="connectorData.nuvolosConnector"
                                        buttonClass="font-weight-bold caption"
                                        buttonColor="secondary"
                                        :isTextButton="true"
                                        buttonName="copy to clipboard"></copy-to-clipboard>
                                </div>
                                <CodeHighlight :language="connectorData.language" :code="connectorData.nuvolosConnector" />
                            </div>
                        </v-card-text>
                    </v-card>
                    <v-radio class="mt-7" label="Connect from local computer" :value="connectorOptions.FROM_LOCAL_COMPUTER"></v-radio>
                    <v-card
                        :elevation="selectedConnectorOption === connectorOptions.FROM_LOCAL_COMPUTER ? 2 : 0"
                        :disabled="selectedConnectorOption === connectorOptions.FROM_NUVOLOS"
                        outlined>
                        <v-card-text>
                            <v-banner class="mb-2">
                                <v-avatar slot="icon" color="white" size="60">
                                    <v-icon icon="info" color="info">info</v-icon>
                                </v-avatar>
                                <ol class="info--text">
                                    <li v-for="(line, index) in connectorData.fromLocalComputerMessage" class="info--text subtitle-2" :key="index">
                                        <span v-html="line"></span>
                                    </li>
                                    <li>
                                        Please follow the setup steps detailed
                                        <a :href="connectorData.externalConfigLink" target="_blank">here</a>
                                        to configure your local machine.
                                    </li>
                                    <li>Paste the code shown below into a {{ connectorData.appName }} editor in your local computer to run your query.</li>
                                </ol>
                            </v-banner>
                            <div class="d-flex align-center justify-space-between" style="background-color: rgba(23, 168, 193, 0.1); width: 100%">
                                <span class="mb-0 mt-0 ml-2 font-weight-bold caption" style="color: rgba(0, 0, 0, 0.54)">Local Connector</span>
                                <copy-to-clipboard
                                    :textToCopy="connectorData.localComputerConnector"
                                    buttonClass="font-weight-bold caption"
                                    buttonColor="secondary"
                                    :isTextButton="true"
                                    buttonName="copy to clipboard"></copy-to-clipboard>
                            </div>
                            <CodeHighlight :language="connectorData.language" :code="connectorData.localComputerConnector" />
                        </v-card-text>
                    </v-card>
                </v-radio-group>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" text @click="getDataDialog = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
import { mapState } from 'vuex'
const CopyToClipboard = () => import('@/components/CopyToClipboard')
const CodeHighlight = () => import('@/components/CodeHighlight')
export default {
    name: 'GetTableData',
    mixins: [appTypeAndImageLink],
    components: {
        CopyToClipboard,
        CodeHighlight
    },
    props: {
        connectorData: Object
    },
    data() {
        return {
            getDataDialog: false,
            selectedConnectorOption: 'nuvolos',
            connectorOptions: {
                FROM_NUVOLOS: 'nuvolos',
                FROM_LOCAL_COMPUTER: 'computer'
            }
        }
    },
    methods: {
        customAppIcon(appName) {
            const cdn = 'https://dlcfc4rxk1sfk.cloudfront.net/nuvolos/app_logos/'
            switch (appName) {
                case 'jupyter':
                    return cdn + 'jupyter-logo.svg'
                case 'rstudio':
                    return cdn + 'rstudio-icon.svg'
                case 'matlab':
                    return cdn + 'matlab-icon.png'
                case 'stata':
                    return cdn + 'stata-logo-blue.svg'
            }
        }
    },
    computed: {
        ...mapState('userStore', ['snowflakeTokens']),
        ...mapState('snapshotStore', ['tablesPath']),
        databaseConnectionSecrets() {
            const username = this.$store.state.userInfo.username
            const password = this.snowflakeTokens.access_token
            const schema = this.tablesPath.schema
            const database = this.tablesPath.db
            const fullData = [
                { itemName: 'Username', data: username, hideSecret: false },
                { itemName: 'Password', data: password, hideSecret: true },
                { itemName: 'Schema', data: schema, hideSecret: false },
                { itemName: 'Database', data: database, hideSecret: false }
            ]
            return fullData
        }
    },
    watch: {
        getDataDialog: function (nextValue, preValue) {
            if (preValue === true && nextValue === false) {
                this.$data.selectedConnectorOption = this.$data.connectorOptions.FROM_NUVOLOS
            }
        }
    }
}
</script>
